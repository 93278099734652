import React from 'react';
import { ImageType } from 'types/CommonTypes';
import {
    ImageMobile,
    LeftWrapper,
    RightWrapper,
    StyledSectionWrapper,
    TextDescription,
    Image,
    TextTitle,
} from './SafeCreditAmount.styled';

import { H2 } from 'styles/Typography.styled';

export interface SafeCreditAmountProps {
    descriptionLeft: string;
    descriptionRight: string;
    imageLeft: ImageType;
    imageRight: ImageType;
    titleLeft: string;
    titleRight: string;
}

export const SafeCreditAmount = ({
    descriptionLeft,
    descriptionRight,
    imageLeft,
    imageRight,
    titleLeft,
    titleRight,
}: SafeCreditAmountProps) => {
    return (
        <StyledSectionWrapper>
            <LeftWrapper>
                <Image alt={imageLeft?.altText} src={imageLeft?.sourceUrl} />
                <ImageMobile
                    alt={imageLeft?.altText}
                    src={imageLeft?.sourceUrl}
                />
                <TextTitle>{titleLeft}</TextTitle>
                <TextDescription>
                    <div
                        dangerouslySetInnerHTML={{ __html: descriptionLeft }}
                    />
                </TextDescription>
            </LeftWrapper>
            <RightWrapper>
                <H2>{titleRight}</H2>
                <TextDescription>{descriptionRight}</TextDescription>
                <Image alt={imageRight?.altText} src={imageRight?.sourceUrl} />
                <ImageMobile
                    alt={imageRight?.altText}
                    src={imageRight?.sourceUrl}
                />
            </RightWrapper>
        </StyledSectionWrapper>
    );
};
