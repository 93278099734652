import { UniversalHero } from 'components/UniversalHero/UniversalHero';
import * as React from 'react';
import Layout from 'layout/index';

import { SafeCreditDetails } from 'sections/SafeCreditDetails/SafeCreditDetails';

import { SafeCreditAmount } from 'sections/SafeCreditAmount/SafeCreditAmount';
import { getDisabledPages } from 'utils/utils';
import { NotFoundPageInfo } from 'sections/NotFoundPageInfo/NotFoundPageInfo';

interface SafeCreditPageProps {
    location: {
        pathname: string;
    };
    pageContext: any;
}

export default ({ pageContext, location }: SafeCreditPageProps) => {
    const creditData = pageContext?.data?.wpInvestment?.investment?.safeCredit;

    const pageTitle = `${pageContext?.title} - ${creditData?.title}`;

    if (!pageContext?.data?.wpInvestment?.investment?.enableSafeCreditPage) {
        return (
            <Layout
                displayPartnersPage={
                    pageContext?.data?.wpInvestment?.investment
                        ?.enablePartnersPage
                }
                headerData={pageContext?.headerData}
                investmentSlug={pageContext?.slug}
                location={location}
                investmentDisabledPages={getDisabledPages(
                    pageContext?.data?.wpInvestment?.investment,
                )}
            >
                <NotFoundPageInfo />
            </Layout>
        );
    }

    return (
        <Layout
            displayPartnersPage={
                pageContext?.data?.wpInvestment?.investment?.enablePartnersPage
            }
            headerData={pageContext?.headerData}
            investmentSlug={pageContext?.slug}
            pageTitle={pageTitle}
            location={location}
            displayBanner
            investmentDisabledPages={getDisabledPages(
                pageContext?.data?.wpInvestment?.investment,
            )}
        >
            <>
                <UniversalHero
                    background={creditData?.titleBackground?.sourceUrl}
                    customBreadcrumbsText={'BK2%'}
                    text={creditData?.description}
                    title={creditData?.title}
                />
                <SafeCreditDetails
                    description={creditData?.forWhoDescription}
                    title={creditData?.forWhoTitle}
                    image={creditData?.forWhoImage}
                />
                <SafeCreditAmount
                    descriptionLeft={creditData?.howMuchDescription}
                    titleLeft={creditData?.howMuchTitle}
                    imageLeft={creditData?.howMuchImage}
                    descriptionRight={creditData?.forWhatDescription}
                    imageRight={creditData?.forWhatImage}
                    titleRight={creditData?.forWhatTitle}
                />
            </>
        </Layout>
    );
};
