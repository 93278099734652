import React from 'react';
import { ImageType } from 'types/CommonTypes';
import {
    ImageMobile,
    LeftWrapper,
    RightWrapper,
    StyledSectionWrapper,
    TextDescription,
    Image,
} from './SafeCreditDetails.styled';

import { H2 } from 'styles/Typography.styled';

export interface SafeCreditDetailsProps {
    description: string;
    image: ImageType;
    title: string;
}

export const SafeCreditDetails = ({
    description,
    title,
    image,
}: SafeCreditDetailsProps) => {
    return (
        <StyledSectionWrapper>
            <LeftWrapper>
                <H2>{title}</H2>
                <TextDescription
                    dangerouslySetInnerHTML={{ __html: description }}
                />
            </LeftWrapper>
            <RightWrapper>
                <Image alt={image?.altText} src={image?.sourceUrl} />
                <ImageMobile alt={image?.altText} src={image?.sourceUrl} />
            </RightWrapper>
        </StyledSectionWrapper>
    );
};
